import { Link, useStaticQuery, graphql, PageProps } from "gatsby"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { MiddleEntry, IMiddletEntry } from "../components/MiddleEntry"

type TitleProps = {
  site: {
    siteMetadata: {
      exampleUrl: string
    }
  }
}

type SlideProps = {
  slide: boolean
}

type MiddleEntryProps = {
  data: IMiddletEntry
  key: string
  even: boolean
  slide: boolean
}
const Studies: React.FC<SlideProps> = ({ slide }) => {
  const testpage = {
    title: "Studien",
    description: () => {
      return (
        <div>
          Reiki wurde schon mehrfach wissenschaftlich {' '}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/28874060/"
            className="underline"
          >
            untersucht
          </a>
          . Es zeigt sich positive Wirkung für das Wohlbefinden generell aber auch bei {' '}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/21821642/"
            className="underline"
          >
            Burnout
          </a>
          .{' '} Oder bei Schmerzen
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/24582620/"
            className="underline"
          >
            
          </a>{" "}
          z.B.: durch {" "}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/26760383/"
            className="underline"
          >
            Arthroplastik
          </a>{" "}
          . Man konnte es mit den bekannten phyiskalischen Methoden
          jedoch noch nicht gut {" "}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/23210468/"
            className="underline"
          >
            messen
          </a>
          . Reiki kann das Wohlbefinden steigern, löst Blockaden und tut einfach gut. Es ist
          kein Ersatz für eine Massage, ärztliche oder psychologischen
          Behandlung.
          <br />
          <br />
        </div>
      )
    },
  }

  return (
    <div>
      <MiddleEntry
        data={testpage}
        key={`p-item-index-2`}
        even={false}
        slide={slide}
      >
        <StaticImage
          src="../images/documents3.jpg"
          width={600}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Wissenschaftliche Studien"
          style={{ marginBottom: `1.45rem`, borderRadius: `10px` }}
        />
      </MiddleEntry>
    </div>
  )
}

export default Studies
