import { useLocation } from "@reach/router";
import { PageProps } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Studies from "../components/Studies";

type TitleProps = {
  site: {
    siteMetadata: {
      exampleUrl: string
    }
  }
}

const StudienPage: React.FC<PageProps> = () => {
  const location = useLocation()

  const testpage = {
    title: "Studien",
    description: () => {
      return (
        <div>
          Reiki wurde schon mehrfach wissenschaftlich &nbsp;
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/28874060/"
            className="underline"
          >
            untersucht
          </a>
          . Es zeigt sich positive Wirkung für das Wohlbefinden, bei &nbsp;
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/21821642/"
            className="underline"
          >
            Burnout
          </a>
          , &nbsp;
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/24582620/"
            className="underline"
          >
            Schmerzen
          </a>{" "}
          z.B.: durch &nbsp;
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/26760383/"
            className="underline"
          >
            Arthroplastik
          </a>{" "}
          und anderem. Man konnte es mit den bisherigen phyiskalischen Methoden
          jedoch nicht gut &nbsp;
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/23210468/"
            className="underline"
          >
            messen
          </a>
          . Es steigert Wohlbefinden, löst Blockaden und tut einfach gut. Es ist
          kein Ersatz für eine Massage, ärztliche oder psychologischen
          Behandlung.
          <br />
          <br />
        </div>
      )
    },
  }

  const data = { slide: false }

  return (
    <>
      <Seo title="Reiki Studien Beispiele" />
      <Layout location={location}>
        Studien über Reiki
        <Studies slide={false}></Studies>
      </Layout>
    </>
  )
}

export default StudienPage
